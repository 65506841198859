<template>
	<section>
		<AsiPageTitle :icon="icons.user" :title="fullName" :loading="loading" :avatar-url="avatarUrl">
			<template v-slot:menu-actions v-if="rMasterData">
				<AsiMenuItem :icon="icons.delete" :label="$t('user.terms.delete')" @click="performDelete"/>
				<template v-if="userState !== undefined">
					<AsiMenuItem v-if="userState === userStates.created" :icon="icons.mail" :label="$t('user.terms.resendConfirmation')" @click="resendConfirmation"/>
					<AsiMenuItem v-if="userState === userStates.created || userState === userStates.active"
					             :icon="icons.archive" :label="$t('user.terms.archive')" @click="performStateChange(userStates.archived)"/>
					<AsiMenuItem v-if=" userState === userStates.archived"
					             :icon="icons.unarchive" :label="$t('user.terms.unarchive')" @click="performStateChange(userStates.created)"/>
				</template>
			</template>
			<template v-slot:subtitle>
				<AsiBreadcrumbs :entries="path" hide-icons link-entries/>
			</template>
		</AsiPageTitle>
		<AsiTabs v-model="activeTab">
			<v-tab key="details">
				{{ $t('user.singular') }}
				<v-icon>{{ icons.user }}</v-icon>
			</v-tab>
			<v-tab-item key="details">
				<UserTabDetails :user="user" @change="loadUser"/>
			</v-tab-item>

			<v-tab key="rights" v-if="canSeeRights">
				{{ $t('ui.terms.rights') }}
				<v-icon>{{ icons.rights }}</v-icon>
			</v-tab>
			<v-tab-item key="rights" v-if="canSeeRights">
				<UserTabRights :id="id"/>
			</v-tab-item>
		</AsiTabs>

		<AsiConfirmDialog ref="confirm"/>
	</section>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import {IUserAdmin} from "@/models/user/UserAdminModels";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import UserHelper from "@/models/user/UserHelper";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiTabs from "@/components/common/AsiTabs";
	import {AttachmentMediaSize, Routes, UserState} from "@/helpers/constants";
	import PathEntry from "@/models/PathEntry";
	import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
	import UserTabDetails from "@/components/user/admin/UserTabDetails.vue";
	import UserTabRights from "@/components/user/admin/UserTabRights.vue";
	import AsiMenuItem from "@/components/common/AsiMenuItem.vue";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import {IUserShop} from "@/models/user/UserShopModels";

	@Component({
		components: {
			AsiConfirmDialog,
			AsiMenuItem,
			UserTabRights, UserTabDetails, AsiBreadcrumbs, AsiTabs, AsiContentContainer, AsiPageTitle
		}
	})
	export default class User extends mixins(RightChecks) {

		@Prop({type: String, required: true})
		public id!: string;

		public icons = Icon;
		public userStates = UserState;
		public loading: boolean = false;
		public user: IUserAdmin | null = null;

		public get fullName(): string | null {
			return UserHelper.fullName(this.user);
		}

		public get avatarUrl(): string | null {
			return this.user === null ? null : UserHelper.avatarUrl(this.user, AttachmentMediaSize.s);
		}

		public get path(): PathEntry[] {
			return this.user === null ? [] : UserHelper.path(this.user, true);
		}

		public get activeTab(): number {
			return this.$store.getters['ui/activeTabAdmin']('user');
		}

		public set activeTab(index: number) {
			this.$store.commit('ui/activeTabAdmin', {key: 'user', activeTab: index});
		}

		public get userState(): UserState | undefined {
			return this.user?.state;
		}

		public get canSeeRights(): boolean {
			if (this.rAdmin) return true;
			const ownModel = this.$store.getters['user/model'] as IUserShop | null;
			return this.rCustomerManager && ownModel !== null && this.user !== null && ownModel.customer.id === this.user.customer.id;
		}

		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadUser();
		}

		public loadUser(): void {
			this.loading = true;
			this.$userServiceAdmin.user(this.id)
				.then(model => this.user = model)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}

		public performDelete(): void {
			const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;

			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				if (this.user !== null) {
					this.loading = true;
					this.$userServiceShop.delete(this.user.id)
						.then(() => {
							this.$router.push({name: Routes.adminUsers});
						})
						.catch(() => Snackbar.deleteError())
						.finally(() => this.loading = false);

				}
			});
		}

		public performStateChange(newState: UserState): void {
			if (this.userState !== undefined) {

				this.loading = true;
				this.$userServiceAdmin.updateState(this.id, newState)
					.then(() => this.loadUser())
					.catch(() => Snackbar.updateError())
					.finally(() => this.loading = false);
			}
		}

		public resendConfirmation(): void {
			if (this.user !== null) {
				this.$authService.resendRegistrationConfirmation(this.user.username)
					.then(() => Snackbar.show(this.$t('user.terms.resendConfirmationSuccess'), 'success'))
					.catch(() => Snackbar.updateError())
					.finally(() => this.loading = false);
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
